<template>
  <div>
    <div class="card">
      <Toolbar class="mb-4">
        <template #start>
          <Button
            label="IO hinzufügen"
            icon="pi pi-plus"
            class="p-button-success mr-2"
            @click="openNew(null)"
          />
          <Button
            label="IO löschen"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="confirmDeleteSelected"
            :disabled="!selectedIOs || !selectedIOs.length"
          />
        </template>

        <template #end>
          <FileUpload
            mode="basic"
            accept="image/*"
            :maxFileSize="1000000"
            label="Import"
            chooseLabel="Import"
            class="mr-2 inline-block"
          />
          <Button
            label="Export"
            icon="pi pi-upload"
            class="p-button-help"
            @click="exportCSV($event)"
          />
        </template>
      </Toolbar>

      <DataTable
        ref="dt"
        :value="io_data"
        v-model:selection="selectedIOs"
        dataKey="_id"
        :loading="loading"
        :paginator="true"
        :rows="50"
        v-model:filters="filters"
        filterDisplay="row"
        :globalFilterFields="['metadata.label', 'metadata.description', 'metadata.department', 'metadata.category']"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 50, 100]"
        currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Modulen"
        responsiveLayout="scroll"
        :getCategory="getCategory"
      >
        <template #empty> Keine IO-Einträge gefunden...</template>
        <template #loading> Daten werden geladen... bitte warten </template>
        <template #header>
          <div class="table-header flex justify-content-between">
            <h5 class="mb-2 m-md:0 as-md-center">PLC-Module verwalten</h5>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText v-model="filters['global'].value" placeholder="suche Eintrag..." />
            </span>
          </div>
        </template>

        <Column selectionMode="multiple" :exportable="false"></Column>
        <Column field="metadata.icon" header="Icon" :sortable="false">
          <template #body="slotProps">
            <Button
              v-if="slotProps.data.metadata.icon"
              :icon="slotProps.data.metadata.icon"
              class="p-button-rounded p-button-primary mr-2"
            />
          </template>
        </Column>
        <Column field="metadata.category" header="Kategorie" :sortable="true" filterField="metadata.category" :showFilterMenu="false" >
          <template #body="slotProps">
            <span>{{ slotProps.data.metadata.category }}</span>
          </template>
          <template v-if="getCategory !== null" #filter="{filterModel, filterCallback}">
              <MultiSelect v-model="filterModel.value" @change="filterCallback()" :options="getCategory" optionLabel="label" optionValue="key" placeholder="Alle" class="p-column-filter">
                  <template #option="slotProps">
                      <div class="p-multiselect-representative-option">
                          <p>{{ slotProps.option.label }}</p>
                      </div>
                  </template>
              </MultiSelect>
          </template>
        </Column>
        <Column field="moduleName" header="Modulname" :sortable="true" filterField="moduleName">
          <template v-if="fullSearch === true" #filter="{filterModel, filterCallback}">
            <InputText v-model="filterModel.value" @keyup="filterCallback()" placeholder="suchen..." />
          </template>
        </Column>
        <Column field="metadata.label" header="Name" :sortable="true" filterField="metadata.label">
          <template v-if="fullSearch === true" #filter="{filterModel, filterCallback}">
              <InputText v-model="filterModel.value" @keyup="filterCallback()" placeholder="suchen..." />
          </template>
        </Column>
        <Column field="metadata.description" header="Beschreibung" :sortable="true" filterField="metadata.description">
          <template v-if="fullSearch === true" #filter="{filterModel, filterCallback}">
              <InputText v-model="filterModel.value" @keyup="filterCallback()" placeholder="suchen..." />
          </template>
        </Column>
        <Column field="metadata.department" header="Anlage" :sortable="true" filterField="metadata.department" :showFilterMenu="false" >
          <template v-if="getDepartments !== null" #filter="{filterModel, filterCallback}">
              <MultiSelect v-model="filterModel.value" @change="filterCallback()" :options="getDepartments" optionLabel="label" optionValue="key" placeholder="Alle" class="p-column-filter">
                  <template #option="slotProps">
                      <div class="p-multiselect-representative-option">
                          <p>{{ `[${slotProps.option.key}] ${slotProps.option.label}` }}</p>
                      </div>
                  </template>
              </MultiSelect>
          </template>
        </Column>
        <Column field="source.type" header="IO-Art" :sortable="true" filterField="source.type" :showFilterMenu="false" >
          <template v-if="fullSearch === true" #filter="{filterModel, filterCallback}">
              <InputText v-model="filterModel.value" @keyup="filterCallback()" placeholder="suchen..." />
          </template>
        </Column>
        <Column field="source.server" header="Datenquelle" :sortable="true" filterField="source.server">
          <template v-if="fullSearch === true" #filter="{filterModel, filterCallback}">
              <InputText v-model="filterModel.value" @keyup="filterCallback()" placeholder="suchen..." />
          </template>
        </Column>
        <Column :exportable="false" style="min-width: 8rem">
          <template #body="slotProps">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-info mr-2"
              @click="editProduct(slotProps.data)"
            />
            <Button
              icon="pi pi-copy"
              class="p-button-rounded p-button-secondary mr-2"
              @click="openNew(slotProps.data)"
            />
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-warning"
              @click="confirmDeleteProduct(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>

    <Dialog
      v-model:visible="productDialog"
      header="Modulkonfiguration"
      :modal="true"
      class="p-fluid"
      @hide="hideDialog"
      :style="{ width: '50vw' }"
      :breakpoints="{ '960px': '85vw', '641px': '100vw' }"
    >
      <div class="formgrid grid">
        <div class="field col">
          <div class="field text-center">
            <span :class="ioDialogData.metadata.icon + ' mif-5x'"></span>
          </div>
        </div>

      </div>

        <div class="formgrid grid">
          <div class="field col-6">
            <label for="name">Name (Klartext)</label>
            <InputText
              id="name"
              v-model.trim="ioDialogData.metadata.label"
              required="true"
              autofocus
              :class="'inputfield' + { invalid: submitted && !ioDialogData.metadata.label }"
            />
            <small class="error" v-if="submitted && !ioDialogData.metadata.label"
              >Name <b>muss</b> ausgefüllt werden!</small
            >
          </div>
          <div class="field col-6">
            <label for="moduleName">Modulname (Softwaremodul, eindeutige Bezeichnung)</label>
            <InputText
              id="moduleName"
              v-model.trim="ioDialogData.moduleName"
              autofocus
            />
          </div>
        </div>
        <div class="field">
          <label for="description">Beschreibung</label>
          <Textarea
            id="description"
            v-model="ioDialogData.metadata.description"
            required="true"
            rows="3"
            cols="20"
          />
        </div>

        <div class="formgrid grid mt-2">
          <div class="field col-6">
            <label for="categoryStatus" class="mb-3">Kategorie</label>
            <Dropdown
              id="categoryStatus"
              v-model="ioDialogData.metadata.category"
              :options="getCategory"
              optionLabel="label"
              optionValue="key"
              placeholder="Wetterstation, Heizung,..."
            >
            </Dropdown>
          </div>

          <div class="field col-6">
            <label for="departmentStatus" class="mb-3">Anlage</label>
            <Dropdown
              id="departmentStatus"
              v-model="ioDialogData.metadata.department"
              :options="getDepartments"
              optionLabel="label"
              optionValue="key"
            >
              <template #option="slotProps">
                <div>
                  <span>{{ `[${slotProps.option.key}]  ${slotProps.option.label}`  }}</span>
                </div>
              </template>
            </Dropdown>
          </div>
        </div>

        <div class="formgrid grid mt-2">
          <div class="field col-6">
            <label for="parameterObject" class="mb-3">Zugeordnetes Parameterobjekt</label>
            <Dropdown
              id="parameterObject"
              v-model="ioDialogData.bindings.moduleScheme"
              :options="getModuleScheme"
              optionLabel="label"
              optionValue="scheme"
              placeholder="ventsController,..."
            ></Dropdown>
          </div>

          <div class="field col-6">
            <label for="icon" class="mb-3">Icon</label>
            <Dropdown
              id="icon"
              v-model="ioDialogData.metadata.icon"
              :filter="true"
              placeholder="Icon wählen"
              :options="getIcons"
              optionLabel="key"
              optionValue="icon"
            >
              <template #value="slotProps">
                <div>
                  <span :class="slotProps.value"></span>
                  <span class="text-small"> [{{ slotProps.value }}]</span>
                </div>
              </template>

              <template #option="slotProps">
                <div>
                  <span class="text-5xl" :class="slotProps.option.icon"></span>
                  <span class="text-small"> [{{ slotProps.option.key }}]</span>
                </div>
              </template>
            </Dropdown>
          </div>
        </div>

        <div class="field">
          <div class="formgrid grid">
            <div class="field col-6">
              <label for="userRead">Lesen</label>
              <TreeSelect
                id="userRead"
                v-model="ioDialogData.permissions.read"
                :options="getUserGroup"
                selectionMode="checkbox"
                placeholder="lesen"
              />
            </div>
            <div class="field col-6">
              <label for="userWrite">Schreiben</label>
              <TreeSelect
                id="userWrite"
                v-model="ioDialogData.permissions.write"
                :options="getUserGroup"
                optionLabel="label"
                optionKey="role"
                selectionMode="checkbox"
                placeholder="schreiben"
              />
            </div>
          </div>
        </div>


        <div class="field">
          <label class="mb-3">IO-Art</label>
          <div class="formgrid grid">
            <div class="field-radiobutton col-6">
              <RadioButton
                id="type_opcua"
                name="type"
                value="opcua"
                v-model="ioDialogData.source.type"
              />
              <label for="type_opcua">OPC-UA</label>
            </div>
            <div class="field-radiobutton col-6">
              <RadioButton
                id="type_virtual"
                name="type"
                value="virtual"
                v-model="ioDialogData.source.type"
              />
              <label for="type_virtual">Virtueller Wert</label>
            </div>
            <div class="field-radiobutton col-6">
              <RadioButton
                id="type_external"
                name="type"
                value="external"
                v-model="ioDialogData.source.type"
              />
              <label for="type_external">Externer Wert</label>
            </div>
            <div class="field-radiobutton col-6">
              <RadioButton
                id="type_fixed"
                name="type"
                value="fixed"
                v-model="ioDialogData.source.type"
              />
              <label for="type_fixed">Fixer Wert</label>
            </div>
          </div>
        </div>


          <div v-if="ioDialogData.source.type === 'opcua'" class="formgrid grid mt-2" >
            <div class="field col-8">
              <label for="description">OPC-UA Verzeichnis</label>
              <Tree
                :value="opcTree"
                :loading="!opcTree"
                selectionMode="single"
                :expandedKeys="opcTreeExpandedKeys"
                :selectionKeys="opcTreeSelection"
                @node-select="onOpcTreeSelect"
                scrollHeight="500px"
              ></Tree>
            </div>

              <div class="field col-4 mt-4">
                <Button
                  label="Modulschema aus Modul erstellen"
                  icon="pi pi-plus"
                  class="p-button-success mr-2"
                  @click=createSchema(opcTreeSelectedNode)
                  :disabled="!opcTreeSelectedNode"
                />
              </div>

              <div class="field col-12">
                <label>OPC-UA NodeID</label>
                <InputText
                  id="path"
                  v-model='nodeId'
                  class="inputtext-sm"
                  readonly
                />
              </div>
          </div>

        <div class="formgrid grid">
          <div class="field col">
            <label>Redis HASH</label>
            <InputText
              id="redisHash"
              v-model='redisHash'
              class="inputtext-sm"
              readonly
            />
          </div>
        </div>

        <div class="formgrid grid">
          <div class="field col">
            <label>MongoDB Modul-ID</label>
            <InputText id="_id" v-model="ioDialogData._id" readonly />
          </div>
        </div>

      <template #footer>
        <Button
          label="Abbrechen"
          icon="pi pi-times"
          class="p-button-text"
          @click="hideDialog"
        />
        <Button
          label="Speichern"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveProduct"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="deleteProductDialog"
      :style="{ width: '450px' }"
      header="Bestätigung"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="ioDialogData"
          >Soll der Eintrag wirklich aus der Datenbank gelöscht werden: <br />
          <b>{{ ioDialogData.source.identifier }}</b> ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Nein"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteProductDialog = false"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteProduct"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="deleteProductsDialog"
      :style="{ width: '450px' }"
      header="Bestätigung"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="ioDialogData"
          >Sollen die Einträge wirklich aus der Datenbank gelöscht werden?</span
        >
      </div>
      <template #footer>
        <Button
          label="Nein"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteProductsDialog = false"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedProducts"
        />
      </template>
    </Dialog>

    <Dialog v-model:visible="showDiagModuleScheme" :closable="false" :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :style="{ width: '450px' }" :modal="true" class="p-fluid">
        <template #header>
            <p class="nice-font"><i class="fi fi-rr-text"></i><span class="pl-1 mt-1">Neues Modul Schema erstellen</span></p>
        </template>
        <div @keydown.esc="hideNameDiag" @keydown.enter="saveDiagModuleScheme(opcTreeSelectedNode)">
            <Panel>
                <div class="grid p-fluid">
                    <div class="col-12 pl-5 pr-5">
                        <span class="p-float-label mr-2">
                            <InputText id="periodName" ref="periodName" v-model="opcTreeSelectedNode.scheme" locale="de-DE" class="nice-font inputtext-param text-center text-5xl" />
                            <label for="periodName" class="mr-2">Schema Name (scheme)</label>
                        </span>
                    </div>
                    <div class="col-12 pl-5 pr-5">
                        <span class="p-float-label mr-2">
                            <InputText id="periodName" ref="periodName" v-model="opcTreeSelectedNode.label" locale="de-DE" class="nice-font inputtext-param text-center text-5xl" />
                            <label for="periodName" class="mr-2">Bezeichnung (label)</label>
                        </span>
                    </div>
                </div>
            </Panel>
        </div>
        <template #footer>
        <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideDiagModuleScheme" />
        <Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveDiagModuleScheme(opcTreeSelectedNode)" />
        </template>
    </Dialog>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
// import store from "@/store";
import { mapGetters } from "vuex";
// import tree from "@/data/tree.json";
import { splitNodeId, combineNodeId, combineRedisHash, mongoResponseToast, processModuleSchemeFromTree, flattenTree } from "../helpers";
// import mainService from "@/services/mainService.js";
import SocketioService from "@/services/socketioService.js";

export default {
  name: "moduleConfig",
  data() {
    return {
      loading: false,
      fullSearch: false,
      products: null,
      productDialog: false,
      showDiagModuleScheme: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      product: {},
      ioDialogData: {
        source: {},
        metadata: {},
      },
      io_type: null,
      // opc_browse: {},
      opc_browse: [],
      opcTree: [],
      opcTreeSelection: null,
      opcTreeExpandedKeys: null,
      opcTreeSelectedNode: null,
      io_data: null,
      selectedIOs: null,
      filters: null,
      submitted: false,
    };
  },
  productService: null,
  computed: {
    ...mapGetters({
      getUserGroup: 'users/getUserGroups',
      getCategory: 'types/getCategory',
      getDepartments: 'types/getDepartments',
      getUnits: 'types/getUnits',
      getIcons: 'types/getIcons',
      getModules: 'opcua/getModules',
      getModuleScheme: 'opcua/getModuleScheme',
      getModuleTypes: 'opcua/getModuleTypes',
      getModuleBindings: 'opcua/getModuleBindings',
    }),
    nodeId: function () {
      return combineNodeId(this.ioDialogData.source.ns, this.ioDialogData.source.identifier);
    },
    redisHash: function () {
      return combineRedisHash(this.ioDialogData.source.server, this.ioDialogData.source.identifier);
    },
  },
  watch: {
  },
  created() {
    // this.productService = new ProductService();
    this.initFilters();
    this.$store.dispatch('users/loadUserGroups');
    this.$store.dispatch('types/loadUnitTypes');
    this.$store.dispatch('types/loadCategoryTypes');
    this.$store.dispatch('types/loadDepartmentTypes');
    this.$store.dispatch('types/loadIconTypes');
    this.$store.dispatch('opcua/loadModules');
    this.$store.dispatch('opcua/loadModuleScheme');
    this.$store.dispatch('opcua/loadModuleTypes');
    this.$store.dispatch('opcua/loadModuleBindings');
  },
  mounted() {
    // this.productService.getProducts().then((data) => (this.products = data));
    // this.loading = true;
    // this.registerSocketIoEvents();
    // this.getParameter();
    this.getPageData();
  },
  beforeUnmount() {
    this.loading = false;
    this.ioDialogData = {};
    this.opcTree = [];
    this.opcTreeSelection = null;
    this.opcTreeExpandedKeys = null;
    this.opcTreeSelectedNode = null;
    this.selectedIOs = null;
    this.submitted = false;
    this.io_data = null;
  },
  methods: {
    resetDialogData() {
      this.ioDialogData = {
        source: {},
        metadata: {},
        bindings: {},
        permissions: {},
        _id: null,
        moduleName: ''
      };
      this.opcTreeSelection = null;
      this.opcTreeExpandedKeys = null;
      // this.opcTree = [];
      this.opcTreeSelectedNode = null;
    },
    getPageData() {
      this.loading = true;
      SocketioService.getModules((err, response) => {
        if (!err && response !== null) {
          this.io_data = [ ...response ];
          this.loading = false;
        }
      });
    },
    async getOpcDirectory() {
      if (this.opcTree.length === 0) {
        this.loading = true;
        SocketioService.getOpcuaBrowse(null, (err, response) => {
          if (!err && response !== null && response.length > 0) {
            response.forEach((tree) => {
              this.opcTree.push(tree.children[0]);
            });
            this.loading = false;
          }
        });
      }
    },
    openNew(ioData) {
      this.resetDialogData();
      this.getOpcDirectory();
      console.log(ioData);
      if (ioData !== null) {
        const _obj = JSON.parse(JSON.stringify(ioData));
        this.ioDialogData = _obj;
        this.ioDialogData._id = null;
      } else {
        this.ioDialogData.metadata.icon = "mif-meter";
        this.ioDialogData.source.type = "opcua";
        this.ioDialogData.permissions.read = ["all"];
        this.ioDialogData.permissions.write = ["all"];
      }
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
      this.resetDialogData();
    },
    hideDiagModuleScheme() {
      this.showDiagModuleScheme = false;

    },
    saveProduct() {
      this.submitted = true;

      if (this.ioDialogData.metadata.label.trim() && this.ioDialogData.moduleName) {
        SocketioService.setModules(this.ioDialogData, (err, response) => {
          if (!err && response !== null) {
            this.loading = false;
            const result = mongoResponseToast(response, this.$root);
            if (result) this.getPageData();
          }
        });
      }
      this.productDialog = false;
      this.resetDialogData();
    },
    editProduct(ioData) {
      this.getOpcDirectory();
      // this.ioDialogData = { ...ioData };
      let _opcSel = {};
      let _opcExp = {};
      // DEBUG
      // let _opcArr = {};

      const _obj = JSON.parse(JSON.stringify(ioData));
      this.ioDialogData = _obj;
      const ns = this.ioDialogData.source.ns;
      const nodeId = combineNodeId(ns, this.ioDialogData.source.identifier);
      const splitted = splitNodeId(nodeId);
      const root = splitted.root;
      const application = splitted.application;
      const program = splitted.program;
      // const moduleName = splitted.moduleName;
      // const displayName = splitted.displayName;
      if (root !== undefined) {
        if (application !== undefined) _opcExp[`${combineNodeId(ns, root + '.' + application)}`] = true;
        if (program !== undefined) _opcExp[`${combineNodeId(ns, root + '.' + application + '.' + program)}`] = true;
        // if (moduleName !== undefined) _opcExp[`${combineNodeId(ns, root + '.' + application + '.' + program + '.' + moduleName)}`] = true;
        // if (displayName !== undefined) _opcExp[`${combineNodeId(ns, root + '.' + application + '.' + program + '.' + moduleName + '.' + displayName)}`] = true;
      }
      _opcSel[nodeId] = true;
      this.opcTreeSelection = { ..._opcSel };
      this.opcTreeExpandedKeys = { ..._opcExp };
      this.productDialog = true;
    },
    confirmDeleteProduct(ioData) {
      const _obj = JSON.parse(JSON.stringify(ioData));
      this.ioDialogData = _obj;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      if (this.ioDialogData._id) {
        SocketioService.delModules(this.ioDialogData, (err, response) => {
          if (!err && response !== null) {
            this.loading = false;
            const result = mongoResponseToast(response, this.$root);
            if (result) this.getPageData();
          }
        });
      }
      this.deleteProductDialog = false;
      this.resetDialogData();
    },
    onOpcTreeSelect(node) {
      this.opcTreeSelectedNode = { ...node };
      this.opcTreeSelectedNode.scheme = node.label;
      this.opcTreeSelectedNode.key = null;
      const splittedId = splitNodeId(node.key);
      this.ioDialogData.moduleName = splittedId.moduleName;
      this.ioDialogData.source.server = node.server;
      this.ioDialogData.source.ns = splittedId.ns;
      this.ioDialogData.source.identifier = splittedId.identifier;
      this.ioDialogData.source.root = splittedId.root;
      this.ioDialogData.source.application = splittedId.application;
      this.ioDialogData.source.program = splittedId.program;
      
      this.$toast.add({
        severity: "success",
        summary: node.label,
        detail: `${node.server}@${splittedId.identifier}`,
        life: 3000,
      });
    },
    createSchema(node) {
      this.showDiagModuleScheme = true;
      console.log(node);
    },
    saveDiagModuleScheme(node) {
      this.loading = true;
      const newNode = processModuleSchemeFromTree(node);
      const flattenNode = flattenTree(newNode);
      console.log(newNode);
      console.log(flattenNode);
      if (node.scheme.trim() && node.label.trim()) {
        SocketioService.setModuleSchemeFromTree(flattenNode, (err, response) => {
          if (!err && response !== null) {
            this.loading = false;
            const result = mongoResponseToast(response, this.$root);
            if (result) {
              this.getPageData();
              this.hideDiagModuleScheme();
            }
          }
        });
      }
    },
    expandAll() {
      for (let node of this.nodes) {
        this.expandNode(node);
      }

      this.expandedKeys = { ...this.expandedKeys };
    },
    collapseAll() {
      this.expandedKeys = {};
    },
    findString(_str, _arr) {
      // let _index;
      // _index = _arr.indexOf(_str);
      // return _index;
      for (let i = 0; i < _arr.length; i++) {
        if (_arr[i]["value"] == _str) {
          return _arr[i]["label"];
        }
      }
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.io_data.length; i++) {
        if (this.io_data[i]._id === id) {
          index = i;
          break;
        }
      }

      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      if (this.selectedIOs.length > 0) {
        this.selectedIOs.forEach((entry) => {
          SocketioService.delModules(entry, (err, response) => {
            if (!err && response !== null) {
              this.loading = false;
              const result = mongoResponseToast(response, this.$root);
              if (result) this.getPageData();
            }
          });
        }); 
      }
      this.deleteProductsDialog = false;
      this.selectedIOs = null;
    },
    initFilters() {
      this.filters = {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'metadata.category': { value: null, matchMode: FilterMatchMode.IN },
        'metadata.department': { value: null, matchMode: FilterMatchMode.IN },
        'metadata.label': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'metadata.description': { value: null, matchMode: FilterMatchMode.STARTS_WITH }
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .p-multiselect-representative-option {
      display: inline-block;
      vertical-align: middle;
  }
</style>